import { gql, useQuery } from '@apollo/client'
import types from '@havppen/gql/types'
import { useMemo } from 'react'
import { useApp } from 'src/contexts/AppContext'
import { GET_VOTINGS_WITH_ARTICLE, GET_VOTING_OPTION_USER, GET_VOTING_USER_OPTIONS } from 'src/gql/voting'
import { NIL as NIL_UUID } from 'uuid'
import { mapVotingsWithArticleQueryOptionsToGqlVariables, VotingsWithArticleQueryOptions } from './voting'

type VotingOptionProps = {
  id: string
  name: string | null
  color: string | null
  imageUrl: string | null
  votedCount: number
}
type VotingQuestionProps = {
  id: string
  title: string | null
  content: string | null
  color: string | null
  type: string | null
  options: VotingOptionProps[]
}
export type VotingProps = {
  id: string
  title: string | null
  description: string | null
  isMemberOnly: boolean
  isResultShown: boolean
  votableLimit: number
  votedCount: number
  frequency: string
  startAt: Date | null
  endAt: Date | null
  questions: VotingQuestionProps[]
}

export const useVoting = (votingId: string) => {
  const { data, error, loading, refetch } = useQuery<types.GET_APP_VOTING, types.GET_APP_VOTINGVariables>(
    gql`
      query GET_APP_VOTING($votingId: uuid!) {
        voting(where: { id: { _eq: $votingId }, published_at: { _is_null: false } }) {
          id
          title
          description
          is_member_only
          is_result_shown
          votable_limit
          frequency
          start_at
          end_at
          voting_questions {
            id
            title
            type
            content
            color
            voting_options {
              id
              name
              color
              image_url
              voting_option_user_summary {
                total_count
              }
            }
          }

          voting_members_aggregate {
            aggregate {
              count
            }
          }

          article_votings(where: { article: { online_at: { _lte: "now()" } } }, limit: 1) {
            article {
              id
              title
              slug
            }
          }
        }
      }
    `,
    { variables: { votingId } },
  )

  const voting: VotingWithArticleProps | null = useMemo(() => {
    if (!data || data.voting.length === 0) {
      return null
    }

    const voting = data.voting[0]
    const article =
      voting.article_votings.length > 0 && voting.article_votings[0].article ? voting.article_votings[0].article : null
    return {
      id: voting.id,
      title: voting.title,
      description: voting.description,
      isMemberOnly: voting.is_member_only,
      isResultShown: voting.is_result_shown,
      votableLimit: voting.votable_limit,
      votedCount: voting.voting_members_aggregate?.aggregate?.count || 0,
      frequency: voting.frequency,
      startAt: voting.start_at ? new Date(voting.start_at) : null,
      endAt: voting.end_at ? new Date(voting.end_at) : null,
      questions: voting.voting_questions.map(question => ({
        id: question.id,
        title: question.title,
        content: question.content,
        color: question.color,
        type: question.type,
        options: question.voting_options.map(option => ({
          id: option.id,
          name: option.name,
          color: option.color,
          imageUrl: option.image_url,
          votedCount: option.voting_option_user_summary?.total_count || 0,
        })),
      })),
      article: article
        ? {
            id: article.id,
            title: article.title,
            slug: article.slug,
          }
        : null,
    }
  }, [data])

  return {
    voting,
    loadingVoting: loading,
    errorVoting: error,
    refetchVoting: refetch,
  }
}

export type VotingWithArticleProps = VotingProps & {
  article: {
    id: string
    title: string | null
    slug: string | null
  } | null
}
export const useVotingsWithArticle = (options?: VotingsWithArticleQueryOptions) => {
  const { id: appId } = useApp()
  const { data, error, loading, refetch } = useQuery<
    types.GET_VOTINGS_WITH_ARTICLE,
    types.GET_VOTINGS_WITH_ARTICLEVariables
  >(GET_VOTINGS_WITH_ARTICLE, { variables: mapVotingsWithArticleQueryOptionsToGqlVariables(options || {}, { appId }) })

  const votings: VotingWithArticleProps[] = useMemo(() => {
    if (!data || data.voting.length === 0) {
      return []
    }

    return data.voting.map(voting => {
      const article =
        voting.article_votings.length > 0 && voting.article_votings[0].article
          ? voting.article_votings[0].article
          : null
      return {
        id: voting.id,
        title: voting.title,
        description: voting.description,
        isMemberOnly: voting.is_member_only,
        isResultShown: voting.is_result_shown,
        votableLimit: voting.votable_limit,
        votedCount: voting.voting_members_aggregate?.aggregate?.count || 0,
        frequency: voting.frequency,
        startAt: voting.start_at ? new Date(voting.start_at) : null,
        endAt: voting.end_at ? new Date(voting.end_at) : null,
        questions: voting.voting_questions.map(question => ({
          id: question.id,
          title: question.title,
          content: question.content,
          color: question.color,
          type: question.type,
          options: question.voting_options.map(option => ({
            id: option.id,
            name: option.name,
            color: option.color,
            imageUrl: option.image_url,
            votedCount: option.voting_option_user_summary?.total_count || 0,
          })),
        })),
        article: article
          ? {
              id: article.id,
              title: article.title,
              slug: article.slug,
            }
          : null,
      }
    })
  }, [data])

  return {
    votings,
    loadingVotings: loading,
    errorVotings: error,
    refetchVotings: refetch,
  }
}

export const useIsMemberVoted = (votingId: string, memberId: string) => {
  const { loading, data, error, refetch } = useQuery<
    types.GET_VOTING_OPTION_USER,
    types.GET_VOTING_OPTION_USERVariables
  >(GET_VOTING_OPTION_USER, {
    skip: memberId === NIL_UUID || !votingId,
    variables: {
      memberId,
      votingId,
    },
  })

  return {
    isVoted: data?.voting_option_user ? data.voting_option_user.length > 0 : false,
    loadingIsVoted: loading,
    errorIsVoted: error,
    refetchIsVoted: refetch,
  }
}

export const useMemberVotedOptions = (votingId: string, memberId: string) => {
  const { loading, data, error, refetch } = useQuery<
    types.GET_VOTING_USER_OPTIONS,
    types.GET_VOTING_USER_OPTIONSVariables
  >(GET_VOTING_USER_OPTIONS, {
    skip: memberId === NIL_UUID || !votingId,
    variables: {
      memberId,
      votingId,
    },
  })

  const memberVotedOptionIds = useMemo(() => {
    return data?.voting_option_user.map(option => option.voting_option_id) || []
  }, [data])

  const memberVotedAt = useMemo(() => {
    const created_at = data?.voting_option_user[0]?.created_at
    return created_at ? new Date(created_at) : null
  }, [data])

  return {
    memberVotedAt,
    memberVotedOptionIds,
    loadingMemberVotedOptions: loading,
    errorMemberVotedOptions: error,
    refetchMemberVotedOptions: refetch,
  }
}
