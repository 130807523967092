import { Modal, Skeleton, Spin } from 'antd'
import dayjs from 'dayjs'
import { useSearchParams } from 'next/navigation'
import React, { useContext, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import AuthModalContext from 'src/components/auth/AuthModalContext'
import { useAuth } from 'src/contexts/AuthContext'
import TrackingContext from 'src/contexts/TrackingContext'
import { trpc } from 'src/helpers/trpc'
import { useMemberVotedOptions, useVoting } from 'src/hooks/voting.client'
import { VoteSelectedProps } from '../../cells/voteCard/VoteCard'
import VoteInline from '../../cells/voteInline/VoteInline'
import VoteResultList from '../../cells/voteResultList/VoteResultList'

const VoteInlineContainer: React.FC<{ voteId: string }> = ({ voteId }) => {
  const { isAuthenticated, isAuthenticating, currentMemberId } = useAuth()

  const [votingBlockRef, setVotingBlockRef] = useState<HTMLDivElement | null>(null)
  const searchParams = useSearchParams()
  const voteQueryId = searchParams?.get('voting-id') as string | undefined
  useEffect(() => {
    if (voteQueryId && voteQueryId === voteId) {
      votingBlockRef?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [voteId, voteQueryId, votingBlockRef])

  const { loadingVoting, voting, refetchVoting } = useVoting(voteId)
  const isVotingEnded = voting?.endAt ? voting.endAt.getTime() < new Date().getTime() : false
  const isVotingUnopened = voting?.startAt ? voting.startAt.getTime() > new Date().getTime() : false

  const { loadingMemberVotedOptions, memberVotedOptionIds, memberVotedAt, refetchMemberVotedOptions } =
    useMemberVotedOptions(voteId, currentMemberId)
  const isVoted = memberVotedOptionIds.length > 0

  const { formatMessage } = useIntl()
  const { fingerprint } = useContext(TrackingContext)
  const { mutateAsync: createVotingMemberOptions } = trpc.interaction.createVotingMemberOptions.useMutation()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [modalApi, modalContextHolder] = Modal.useModal()
  const onSubmit = async (selectedVotes: VoteSelectedProps[]) => {
    if (!voting) {
      return
    }

    setIsSubmitting(true)
    try {
      const response = await createVotingMemberOptions({
        fingerprint,
        votingId: voting.id,
        votingOptionIds: selectedVotes.map(vote => vote.selected),
      })
      if (response.code === 'SUCCESS') {
        modalApi.success({ title: '投票成功' })
      } else if (response.code) {
        modalApi.error({ title: formatMessage({ id: `error.${response.code}`, defaultMessage: response.message }) })
      }

      await refetchMemberVotedOptions()
    } catch (error) {
      console.log(error)
    }
    setIsSubmitting(false)
    refetchVoting()
  }

  const { setVisible } = useContext(AuthModalContext)
  const onUnableToVote = () => {
    setVisible?.(true)
  }

  return (
    <div ref={setVotingBlockRef}>
      <Skeleton loading={loadingVoting}>
        {modalContextHolder}
        {voting && (
          <Spin spinning={loadingMemberVotedOptions || isAuthenticating || isSubmitting}>
            {isVoted ? (
              <VoteResultList
                votedNumber={voting.votedCount}
                votedDate={memberVotedAt ? dayjs(memberVotedAt).format('YYYY-MM-DD') : ''}
                duration={{
                  from: voting.startAt ? dayjs(voting.startAt).format('YYYY-MM-DD') : '',
                  due: voting.endAt ? dayjs(voting.endAt).format('YYYY-MM-DD') : '',
                }}
                items={voting.questions.map(question => ({
                  id: question.id,
                  question: question.title || '',
                  options: question.options.map(option => ({
                    id: option.id,
                    content: option.name || '',
                    amount: option.votedCount,
                    selected: memberVotedOptionIds.some(id => id === option.id),
                  })),
                }))}
              />
            ) : (
              <VoteInline
                voted={false}
                votedNumber={voting.votedCount}
                isVotable={isAuthenticated}
                onUnableToVote={onUnableToVote}
                reference={{
                  id: voting.article?.id || '',
                  title: voting.article?.title || '',
                  href: voting.article ? `/article/${voting.article.slug || ''}` : '/',
                }}
                onSubmit={onSubmit}
                status={isVotingEnded ? 'ended' : isVotingUnopened ? 'unopened' : 'ongoing'}
                items={voting.questions.map(question => ({
                  id: question.id,
                  question: question.title || '',
                  options: question.options.map(option => ({
                    id: option.id,
                    content: option.name || '',
                    value: option.id,
                  })),
                }))}
              />
            )}
          </Spin>
        )}
      </Skeleton>
    </div>
  )
}

export default VoteInlineContainer
