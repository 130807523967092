'use client'

import Image, { ImageProps } from 'next/image'
import React from 'react'
import styled from 'styled-components'

const StyledImage = styled(Image)`
  object-fit: cover;
  filter: none !important;
  background-size: 40% !important;
  background-position: center !important;
  background-repeat: no-repeat;
`

const LazyLoadImage: React.FC<ImageProps> = ({ ...props }) => {
  const imageUrl = props.src.toString()
  return <StyledImage {...props} unoptimized={imageUrl.endsWith('.svg') || imageUrl.endsWith('.gif')} />
}

export default LazyLoadImage
