import { Modal, Skeleton, Spin } from 'antd'
import dayjs from 'dayjs'
import { useSearchParams } from 'next/navigation'
import React, { useContext, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import AuthModalContext from 'src/components/auth/AuthModalContext'
import { useAuth } from 'src/contexts/AuthContext'
import TrackingContext from 'src/contexts/TrackingContext'
import { trpc } from 'src/helpers/trpc'
import { useMemberAnsweredOptions, useQa } from 'src/hooks/qa.client'
import { VoteSelectedProps } from '../../cells/voteCard/VoteCard'
import VoteInline from '../../cells/voteInline/VoteInline'
import VoteResultListWithAnswer from '../../cells/voteResultListWithAnswer/VoteResultListWithAnswer'

const QaInlineContainer: React.FC<{ qaId: string }> = ({ qaId }) => {
  const { isAuthenticated, isAuthenticating, currentMemberId } = useAuth()

  const [qaBlockRef, setQaBlockRef] = useState<HTMLDivElement | null>(null)
  const searchParams = useSearchParams()
  const qaQueryId = searchParams?.get('qa-id') as string | undefined
  useEffect(() => {
    if (qaQueryId && qaQueryId === qaId) {
      qaBlockRef?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [qaId, qaQueryId, qaBlockRef])

  const { loadingQa, qa, refetchQa } = useQa(qaId)
  const isQaEnded = qa?.endAt ? qa.endAt.getTime() < new Date().getTime() : false
  const isQaUnopened = qa?.startAt ? qa.startAt.getTime() > new Date().getTime() : false

  const { loadingMemberAnsweredOptions, memberAnsweredOptionIds, memberAnsweredAt, refetchMemberAnsweredOptions } =
    useMemberAnsweredOptions(qaId, currentMemberId)
  const isAnswered = memberAnsweredOptionIds.length > 0

  const { formatMessage } = useIntl()
  const { fingerprint } = useContext(TrackingContext)
  const { mutateAsync: createQaMemberOptions } = trpc.interaction.createQaMemberOptions.useMutation()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [modalApi, modalContextHolder] = Modal.useModal()
  const onSubmit = async (selectedQaOptions: VoteSelectedProps[]) => {
    if (!qa) {
      return
    }

    setIsSubmitting(true)
    try {
      const response = await createQaMemberOptions({
        fingerprint,
        qaId,
        qaOptionIds: selectedQaOptions.map(qaOption => qaOption.selected),
      })
      if (response.code === 'SUCCESS') {
        modalApi.success({ title: '參與成功' })
      } else if (response.code) {
        modalApi.error({ title: formatMessage({ id: `error.${response.code}`, defaultMessage: response.message }) })
      }

      await refetchMemberAnsweredOptions()
    } catch (error) {
      console.log(error)
    }
    setIsSubmitting(false)
    refetchQa()
  }

  const { setVisible } = useContext(AuthModalContext)
  const onUnableToVote = () => {
    setVisible?.(true)
  }

  return (
    <div ref={setQaBlockRef}>
      <Skeleton loading={loadingQa}>
        {modalContextHolder}
        {qa && (
          <Spin spinning={loadingMemberAnsweredOptions || isAuthenticating || isSubmitting}>
            {isAnswered ? (
              <VoteResultListWithAnswer
                votedDate={memberAnsweredAt ? dayjs(memberAnsweredAt).format('YYYY-MM-DD') : ''}
                duration={{
                  from: qa.startAt ? dayjs(qa.startAt).format('YYYY-MM-DD') : '',
                  due: qa.endAt ? dayjs(qa.endAt).format('YYYY-MM-DD') : '',
                }}
                items={qa.questions.map(question => {
                  const options = question.options.map(option => ({
                    id: option.id,
                    content: option.name || '',
                    amount: option.votedCount,
                    isCorrect: option.isCorrect,
                    selected: memberAnsweredOptionIds.some(id => id === option.id),
                  }))

                  const answerOptionId = options.find(option => option.isCorrect)?.id
                  const selectedOptionId = options.find(option => option.selected)?.id

                  return {
                    id: question.id,
                    question: question.title || '',
                    explanation: question.explanation || '',
                    answer: answerOptionId || '',
                    selected: selectedOptionId || '',
                    options,
                  }
                })}
              />
            ) : (
              <VoteInline
                title="趨勢QA"
                endedText="QA 已結束"
                votedText="已參與過 QA 回答"
                peopleVotedText="已參與"
                voted={false}
                votedNumber={qa.votedCount}
                isVotable={isAuthenticated}
                onUnableToVote={onUnableToVote}
                reference={{
                  id: qa.article?.id || '',
                  title: qa.article?.title || '',
                  href: qa.article ? `/article/${qa.article.slug || ''}` : '/',
                }}
                onSubmit={onSubmit}
                status={isQaEnded ? 'ended' : isQaUnopened ? 'unopened' : 'ongoing'}
                items={qa.questions.map(question => ({
                  id: question.id,
                  question: question.title || '',
                  options: question.options.map(option => ({
                    id: option.id,
                    content: option.name || '',
                    value: option.id,
                  })),
                }))}
              />
            )}
          </Spin>
        )}
      </Skeleton>
    </div>
  )
}

export default QaInlineContainer
