import types from '@havppen/gql/types'

export type VotingsWithArticleQueryOptions = {
  limit?: number
  articleCategorySlug?: string | null
}
export const mapVotingsWithArticleQueryOptionsToGqlVariables = (
  options: VotingsWithArticleQueryOptions,
  params: { appId: string },
) => {
  const variables: types.GET_VOTINGS_WITH_ARTICLEVariables = {
    condition: {
      app_id: { _eq: params.appId },
      article_votings: {
        article: options.articleCategorySlug
          ? {
              article_categories: {
                category: {
                  slug: { _eq: options.articleCategorySlug },
                },
              },
            }
          : undefined,
      },
    },
    orderBy: [{ start_at: 'desc_nulls_last' as types.order_by }],
    limit: options.limit,
  }

  return variables
}
