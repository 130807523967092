'use client'

import parse, { domToReact, Element } from 'html-react-parser'
import DOMPurify from 'isomorphic-dompurify'
import dynamic from 'next/dynamic'
import Script from 'next/script'
import React, { memo } from 'react'
import ReactPlayer from 'react-player/lazy'
import { BREAK_POINT } from 'src/contexts/ResponsiveContext'
import LazyLoadImage from '../common/LazyLoadImage'
import QaInlineContainer from '../voting/containers/qaInlineContainer/QaInlineContainer'
import VoteInlineContainer from '../voting/containers/voteInlineContainer/VoteInlineContainer'
import { OembedType } from './content/LazyOembed'
import { StyledContentArticle } from './index.styled'
const LazyOembed = dynamic(() => import('./content/LazyOembed'), { ssr: false })

const cdnDomains = process.env.NEXT_PUBLIC_CDN_DOMAINS?.split(',') || []
const videoPlayerDomains = ['youtube.com']

const ArticleContent: React.FC<{ content: string }> = ({ content }) => {
  const purifiedContent = DOMPurify.sanitize(content, {
    ADD_TAGS: ['iframe', 'widget-qa', 'widget-voting'],
    ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'],
  })
  let oembedIndex = 0

  return (
    <StyledContentArticle>
      {parse(purifiedContent, {
        replace: domNode => {
          if (domNode instanceof Element) {
            switch (domNode.name) {
              case 'script': {
                return
              }

              case 'img': {
                const { src: imgSrc, width, height, alt } = domNode.attribs
                if (!cdnDomains.find(domain => imgSrc.includes(domain)) || !width || !height) {
                  break
                }

                return (
                  <LazyLoadImage
                    src={imgSrc}
                    width={Number(width.replace(/\D/g, ''))}
                    height={Number(height.replace(/\D/g, ''))}
                    alt={alt}
                    sizes={`(max-width: ${BREAK_POINT}px) 100vw, 800px`}
                  />
                )
              }
              case 'iframe': {
                const { height, width, src } = domNode.attribs
                if (!src) break

                let iframeUrl: URL | null = null
                try {
                  if (src.startsWith('//')) {
                    iframeUrl = new URL(`https:${src}`)
                  } else {
                    iframeUrl = new URL(src)
                  }
                } catch (e) {}

                if (iframeUrl && videoPlayerDomains.some(domain => iframeUrl?.host.includes(domain))) {
                  return (
                    <div className="youtube-wrapper">
                      <ReactPlayer light playing url={src} width="100%" height="100%" className="react-player" />
                    </div>
                  )
                }

                const iframeDefaultRatio = height && width ? `${(parseInt(height) / parseInt(width)) * 100}%` : null
                const iframeRatioDesktop = domNode.attribs['data-oembed-ratio-desktop']
                const iframeRatioMobile = domNode.attribs['data-oembed-ratio-mobile']
                if (iframeDefaultRatio || (iframeRatioDesktop && iframeRatioMobile)) {
                  return (
                    <div
                      className="embed-container"
                      style={{
                        ['--iframe-ratio-percentage-default' as any]: iframeDefaultRatio,
                        ['--iframe-ratio-percentage-desktop' as any]: iframeRatioDesktop,
                        ['--iframe-ratio-percentage-mobile' as any]: iframeRatioMobile,
                      }}
                    >
                      <iframe frameBorder={0} scrolling="no" src={src} loading="lazy" />
                    </div>
                  )
                }

                domNode.attribs.loading = 'lazy'
                domNode.attribs.width = '100%'
                if (domNode.attribs.style?.includes('min-width')) {
                  domNode.attribs.style.replace(/min-width(.+)px;/, '')
                }
                break
              }

              case 'blockquote': {
                const oembedUrl = domNode.attribs['data-instgrm-permalink'] as string | undefined
                if (!oembedUrl) {
                  break
                }

                return (
                  <div>
                    {domToReact(domNode.children)}
                    <Script key="instagram-script" async defer src="//www.instagram.com/embed.js" />
                  </div>
                )
              }

              case 'div': {
                const oembedUrl = domNode.attribs['data-oembed-url'] as string | undefined
                let oembedType: OembedType | null = null

                if (!oembedUrl) {
                  break
                }

                switch (true) {
                  case oembedUrl.includes('instagram.com'):
                    oembedType = 'instagram'
                    break
                  case oembedUrl.includes('facebook.com'):
                    oembedType = 'facebook'
                    break
                  case oembedUrl.includes('twitter.com'):
                    oembedType = 'twitter'
                    break
                  case oembedUrl.includes('youtube.com'):
                    oembedType = 'youtube'
                    break
                }

                if (oembedType) {
                  oembedIndex += 1
                  return (
                    <LazyOembed key={oembedUrl} type={oembedType} index={oembedIndex} domNode={domNode}></LazyOembed>
                  )
                }
                break
              }

              case 'widget-qa': {
                const qaId = domNode.attribs['data-qa-id'] as string | undefined
                if (!qaId) {
                  return <></>
                }

                return <QaInlineContainer qaId={qaId} />
              }
              case 'widget-voting': {
                const votingId = domNode.attribs['data-voting-id'] as string | undefined
                if (!votingId) {
                  return <></>
                }

                return <VoteInlineContainer voteId={votingId} />
              }
            }
          }
        },
      })}
    </StyledContentArticle>
  )
}

export default memo(ArticleContent, (prev, next) => prev.content === next.content)
