import { gql } from '@apollo/client'

export const GET_QA_OPTION_USER = gql`
  query GET_QA_OPTION_USER($memberId: String!, $qaId: uuid!) {
    qa_option_user(
      where: { member_id: { _eq: $memberId }, qa_option: { qa_question: { qa_id: { _eq: $qaId } } } }
      limit: 1
    ) {
      id
    }
  }
`

export const GET_QA_USER_OPTIONS = gql`
  query GET_QA_USER_OPTIONS($memberId: String!, $qaId: uuid!) {
    qa_option_user(where: { member_id: { _eq: $memberId }, qa_option: { qa_question: { qa_id: { _eq: $qaId } } } }) {
      id
      qa_option_id
      created_at
    }
  }
`

export const GET_QAS_WITH_ARTICLE = gql`
  query GET_QAS_WITH_ARTICLE($condition: qa_bool_exp, $limit: Int, $orderBy: [qa_order_by!]) {
    qa(where: $condition, limit: $limit, order_by: $orderBy) {
      id
      title
      description
      is_member_only
      is_result_shown
      start_at
      end_at
      qa_questions {
        id
        title
        type
        content
        explanation
        qa_options {
          id
          name
          image_url
          is_correct
          qa_option_user_summary {
            total_count
          }
        }
      }

      qa_members_aggregate {
        aggregate {
          count
        }
      }

      article_qas(where: { article: { online_at: { _lte: "now()" } } }, limit: 1) {
        article {
          id
          title
          slug
        }
      }
    }
  }
`
