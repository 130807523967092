import types from '@havppen/gql/types'

export type QaWithArticleQueryOptions = {
  limit?: number
  articleCategorySlug?: string
}
export const mapQaWithArticleQueryOptionsToGqlVariables = (
  options: QaWithArticleQueryOptions,
  params: { appId: string },
) => {
  const variables: types.GET_QAS_WITH_ARTICLEVariables = {
    condition: {
      app_id: { _eq: params.appId },
      article_qas: {
        article: options?.articleCategorySlug
          ? {
              article_categories: {
                category: {
                  slug: { _eq: options.articleCategorySlug },
                },
              },
            }
          : undefined,
      },
    },
    orderBy: [{ start_at: 'desc_nulls_last' as types.order_by }],
    limit: options?.limit,
  }

  return variables
}
