import { Divider, Flex, Progress, Space, Typography } from 'antd'
import clsx from 'clsx'
import React, { Fragment } from 'react'
import styled from 'styled-components'

export interface VoteResultOptionProps {
  id: React.Key
  content: string
  amount: number
  selected?: boolean
}

export interface VoteResultProps {
  id: React.Key
  question: string
  options: VoteResultOptionProps[]
}

interface VoteResultConfigProps {}

export interface VoteResultListProps extends VoteResultConfigProps {
  votedDate: string
  votedNumber: number
  items: VoteResultProps[]
  duration: {
    from: string
    due: string
  }
}

const CardHeader = styled(Typography.Text)`
  padding: 12px;
  font-size: 18px;
  line-height: 1.44;
  font-weight: 500;
  color: #33301e;
`

const CardBody = styled.div`
  padding: 0 16px;
  .ant-typography {
    font-size: 14px;
    line-height: 1.428;
    color: #858378;
  }
  .ant-progress {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 8px;
    .ant-progress-text {
      flex: 0 0 auto;
      display: block;
      font-size: 16px;
      line-height: 1.5;
      color: #858378;
      width: 42px;
      margin-inline-start: unset;
    }
    .ant-progress-outer {
      flex: 1 1 auto;
      margin-inline-end: unset;
      padding-inline-end: unset;
      position: relative;
    }
    .ant-progress-inner {
      position: absolute;
      top: 0;
    }
  }
  .selected {
    .ant-typography {
      color: #f8b028;
    }
    .ant-progress {
      .ant-progress-text {
        color: #f8b028;
      }
    }
  }
`

const VoteResultCard: React.FC<VoteResultProps & { number: number }> = ({ number, question, options }) => {
  const reducedOptions = options.reduce(
    (acc, cur, index, arr) => {
      acc.total += cur.amount
      if (cur.amount > arr[acc.largest].amount) acc.largest = index
      return acc
    },
    { total: 0, largest: 0 },
  )

  return (
    <Flex vertical gap={12}>
      <CardHeader>
        Q{number}. {question}
      </CardHeader>
      <CardBody>
        <Flex vertical gap={16}>
          {options.map((option, index) => (
            <Flex key={option.id} vertical gap={4} className={clsx({ selected: option.selected })}>
              <Typography.Text>{option.content}</Typography.Text>
              <Progress
                status="normal"
                strokeColor={option.selected ? '#F8B028' : '#858378'}
                trailColor="transparent"
                percent={(option.amount / reducedOptions.total) * 100}
                format={percentage => (percentage ? `${Math.round(percentage)}%` : percentage)}
              />
            </Flex>
          ))}
        </Flex>
      </CardBody>
    </Flex>
  )
}

const ListContainer = styled.div`
  border: 1px solid #e0e0dd;
  background-color: #fafafa;
`

const Header = styled.div`
  padding: 12px 0;
  border-bottom: 1px solid #e0e0dd;
  .ant-divider-vertical {
    min-height: 37px;
    margin-inline: 0;
  }
  .ant-typography {
    font-size: 12px;
    line-height: 1.5;
    font-weight: 500;
    color: #33301e;
  }
`

const Strong = styled(Typography.Text)`
  &.ant-typography {
    font-size: 14px;
    line-height: 1.428;
  }
`

const Body = styled.div`
  padding: 12px;
  .ant-divider {
    margin: auto;
  }
`

const Duration = styled(Typography.Text)`
  &.ant-typography {
    font-size: 12px;
    line-height: 1.5;
    color: #c2c1bb;
    text-align: center;
  }
`

const VoteResultList: React.FC<VoteResultListProps> = ({ votedDate, votedNumber, items, duration }) => (
  <ListContainer>
    <Header>
      <Flex justify="space-evenly" align="center">
        <Strong>民調結果</Strong>
        <Divider type="vertical" />
        <Space align="center" size={4}>
          <Typography.Text>投票人數</Typography.Text>
          <Strong>{new Intl.NumberFormat('zh-TW', { notation: 'compact' }).format(votedNumber)}</Strong>
          <Typography.Text>人</Typography.Text>
        </Space>
        <Divider type="vertical" />
        <Typography.Text>{votedDate}</Typography.Text>
      </Flex>
    </Header>
    <Body>
      <Flex vertical gap={20}>
        {items.map((vote, index) => (
          <Fragment key={vote.id}>
            <VoteResultCard number={index + 1} {...vote} />
            <Divider />
          </Fragment>
        ))}
        <Duration>
          統計時間：{duration.from} - {duration.due}
        </Duration>
      </Flex>
    </Body>
  </ListContainer>
)

export default VoteResultList
