import { gql, useQuery } from '@apollo/client'
import types from '@havppen/gql/types'
import { useMemo } from 'react'
import { useApp } from 'src/contexts/AppContext'
import { GET_QAS_WITH_ARTICLE, GET_QA_OPTION_USER, GET_QA_USER_OPTIONS } from 'src/gql/qa'
import { NIL as NIL_UUID } from 'uuid'
import { mapQaWithArticleQueryOptionsToGqlVariables, QaWithArticleQueryOptions } from './qa'

type QaOptionProps = {
  id: string
  name: string | null
  imageUrl: string | null
  isCorrect: boolean
  votedCount: number
}
type QaQuestionProps = {
  id: string
  title: string | null
  content: string | null
  explanation: string | null
  type: string | null
  options: QaOptionProps[]
}
export type QaProps = {
  id: string
  title: string | null
  description: string | null
  isMemberOnly: boolean
  isResultShown: boolean
  votedCount: number
  startAt: Date | null
  endAt: Date | null
  questions: QaQuestionProps[]
}

export const useQa = (qaId: string) => {
  const { data, error, loading, refetch } = useQuery<types.GET_APP_QA, types.GET_APP_QAVariables>(
    gql`
      query GET_APP_QA($qaId: uuid!) {
        qa(where: { id: { _eq: $qaId }, published_at: { _is_null: false } }) {
          id
          title
          description
          is_member_only
          is_result_shown
          start_at
          end_at
          qa_questions {
            id
            title
            type
            content
            explanation
            qa_options {
              id
              name
              image_url
              is_correct
              qa_option_user_summary {
                total_count
              }
            }
          }

          qa_members_aggregate {
            aggregate {
              count
            }
          }

          article_qas(where: { article: { online_at: { _lte: "now()" } } }, limit: 1) {
            article {
              id
              title
              slug
            }
          }
        }
      }
    `,
    { variables: { qaId } },
  )

  const qa: QaWithArticleProps | null = useMemo(() => {
    if (!data || data.qa.length === 0) {
      return null
    }

    const qa = data.qa[0]
    const article = qa.article_qas.length > 0 && qa.article_qas[0].article ? qa.article_qas[0].article : null
    return {
      id: qa.id,
      title: qa.title,
      description: qa.description,
      isMemberOnly: qa.is_member_only,
      isResultShown: qa.is_result_shown,
      votedCount: qa.qa_members_aggregate?.aggregate?.count || 0,
      startAt: qa.start_at ? new Date(qa.start_at) : null,
      endAt: qa.end_at ? new Date(qa.end_at) : null,
      questions: qa.qa_questions.map(question => ({
        id: question.id,
        title: question.title,
        content: question.content,
        explanation: question.explanation,
        type: question.type,
        options: question.qa_options.map(option => ({
          id: option.id,
          name: option.name,
          imageUrl: option.image_url,
          isCorrect: option.is_correct,
          votedCount: option.qa_option_user_summary?.total_count || 0,
        })),
      })),
      article: article
        ? {
            id: article.id,
            title: article.title,
            slug: article.slug,
          }
        : null,
    }
  }, [data])

  return {
    qa,
    loadingQa: loading,
    errorQa: error,
    refetchQa: refetch,
  }
}

export type QaWithArticleProps = QaProps & {
  article: {
    id: string
    title: string | null
    slug: string | null
  } | null
}
export const useQaWithArticle = (options?: QaWithArticleQueryOptions) => {
  const { id: appId } = useApp()
  const { data, error, loading, refetch } = useQuery<types.GET_QAS_WITH_ARTICLE, types.GET_QAS_WITH_ARTICLEVariables>(
    GET_QAS_WITH_ARTICLE,
    { variables: mapQaWithArticleQueryOptionsToGqlVariables(options || {}, { appId }) },
  )

  const qas: QaWithArticleProps[] = useMemo(() => {
    if (!data || data.qa.length === 0) {
      return []
    }

    return data.qa.map(qa => {
      const article = qa.article_qas.length > 0 && qa.article_qas[0].article ? qa.article_qas[0].article : null
      return {
        id: qa.id,
        title: qa.title,
        description: qa.description,
        isMemberOnly: qa.is_member_only,
        isResultShown: qa.is_result_shown,
        votedCount: qa.qa_members_aggregate?.aggregate?.count || 0,
        startAt: qa.start_at ? new Date(qa.start_at) : null,
        endAt: qa.end_at ? new Date(qa.end_at) : null,
        questions: qa.qa_questions.map(question => ({
          id: question.id,
          title: question.title,
          content: question.content,
          explanation: question.explanation,
          type: question.type,
          options: question.qa_options.map(option => ({
            id: option.id,
            name: option.name,
            imageUrl: option.image_url,
            isCorrect: option.is_correct,
            votedCount: option.qa_option_user_summary?.total_count || 0,
          })),
        })),
        article: article
          ? {
              id: article.id,
              title: article.title,
              slug: article.slug,
            }
          : null,
      }
    })
  }, [data])

  return {
    qas,
    loadingQas: loading,
    errorQas: error,
    refetchQas: refetch,
  }
}

export const useIsMemberQaAnswered = (qaId: string, memberId: string) => {
  const { loading, data, error, refetch } = useQuery<types.GET_QA_OPTION_USER, types.GET_QA_OPTION_USERVariables>(
    GET_QA_OPTION_USER,
    {
      skip: memberId === NIL_UUID || !qaId,
      variables: {
        memberId,
        qaId,
      },
    },
  )

  return {
    isAnswered: data?.qa_option_user ? data.qa_option_user.length > 0 : false,
    loadingIsAnswered: loading,
    errorIsAnswered: error,
    refetchIsAnswered: refetch,
  }
}

export const useMemberAnsweredOptions = (qaId: string, memberId: string) => {
  const { loading, data, error, refetch } = useQuery<types.GET_QA_USER_OPTIONS, types.GET_QA_USER_OPTIONSVariables>(
    GET_QA_USER_OPTIONS,
    {
      skip: memberId === NIL_UUID || !qaId,
      variables: {
        memberId,
        qaId,
      },
    },
  )

  const memberAnsweredOptionIds = useMemo(() => {
    return data?.qa_option_user.map(option => option.qa_option_id) || []
  }, [data])

  const memberAnsweredAt = useMemo(() => {
    const created_at = data?.qa_option_user[0]?.created_at
    return created_at ? new Date(created_at) : null
  }, [data])

  return {
    memberAnsweredAt,
    memberAnsweredOptionIds,
    loadingMemberAnsweredOptions: loading,
    errorMemberAnsweredOptions: error,
    refetchMemberAnsweredOptions: refetch,
  }
}
