import { gql } from '@apollo/client'

export const GET_VOTING_OPTION_USER = gql`
  query GET_VOTING_OPTION_USER($memberId: String!, $votingId: uuid!) {
    voting_option_user(
      where: { member_id: { _eq: $memberId }, voting_option: { voting_question: { voting_id: { _eq: $votingId } } } }
      limit: 1
    ) {
      id
    }
  }
`

export const GET_VOTING_USER_OPTIONS = gql`
  query GET_VOTING_USER_OPTIONS($memberId: String!, $votingId: uuid!) {
    voting_option_user(
      where: { member_id: { _eq: $memberId }, voting_option: { voting_question: { voting_id: { _eq: $votingId } } } }
    ) {
      id
      voting_option_id
      created_at
    }
  }
`

export const GET_VOTINGS_WITH_ARTICLE = gql`
  query GET_VOTINGS_WITH_ARTICLE($condition: voting_bool_exp, $limit: Int, $orderBy: [voting_order_by!]) {
    voting(where: $condition, limit: $limit, order_by: $orderBy) {
      id
      title
      description
      is_member_only
      is_result_shown
      votable_limit
      frequency
      start_at
      end_at
      voting_questions {
        id
        title
        type
        content
        color
        voting_options {
          id
          name
          color
          image_url
          voting_option_user_summary {
            total_count
          }
        }
      }

      voting_members_aggregate {
        aggregate {
          count
        }
      }

      article_votings(where: { article: { online_at: { _lte: "now()" } } }, limit: 1) {
        article {
          id
          title
          slug
        }
      }
    }
  }
`
