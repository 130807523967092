import { ArrowRightOutlined } from '@ant-design/icons'
import type { RadioChangeEvent } from 'antd'
import { Flex, Radio, Space, Typography } from 'antd'
import { MouseEventHandler, useRef } from 'react'
import styled from 'styled-components'
import type { VoteCardProps, VoteProps, VoteSelectedProps } from '../voteCard/VoteCard'

const VoteWrapper = styled(Space)`
  padding: 20px 24px;
  display: flex;
  width: 100%;
`

const Question = styled(Typography.Text)`
  font-size: 18px;
  line-height: 1.44;
  font-weight: 500;
  &::before {
    content: 'Q. ';
  }
`

const RadioContainer = styled(Radio.Group)`
  display: flex;
  flex-flow: column;
  gap: 10px;
  .ant-radio-wrapper {
    font-size: 14px;
    line-height: 1.428;
    &:hover {
      border-color: #f8b028;
      .ant-radio-inner {
        border-color: #f8b028;
      }
    }
    .ant-radio-checked {
      &::after {
        border: 1px solid #f8b028;
      }
      .ant-radio-inner {
        border-color: #f8b028;
        background-color: #f8b028;
      }
    }
  }
  .ant-radio-button-wrapper {
    &:hover {
      color: #f8b028;
    }
  }
  .ant-radio-button-wrapper-checked {
    &,
    &:hover {
      color: #f8b028;
      border-color: #f8b028;
      &::before {
        background-color: #f8b028;
      }
    }
    &:active {
      color: #f8b028;
      border-color: #f8b028;
      &::before {
        background-color: #f8b028;
      }
    }
    &:first-child {
      border-color: #f8b028;
    }
  }
  .ant-wave {
    color: #f8b028;
  }
  .ant-radio-wrapper .ant-radio-input:focus-visible + .ant-radio-inner {
    outline: 4px solid #fad385;
  }
`

const VoteContainer: React.FC<VoteProps & { resultsRef: React.MutableRefObject<VoteSelectedProps[]> }> = ({
  id,
  question,
  options,
  resultsRef,
}) => {
  const handleChange = (event: RadioChangeEvent) => {
    const index = resultsRef.current.findIndex(result => result.id === id)
    resultsRef.current[index].selected = event.target.value
  }

  return (
    <VoteWrapper direction="vertical" size={10}>
      <Question>{question}</Question>
      <RadioContainer onChange={handleChange}>
        {options.map(option => (
          <Radio key={option.id} value={option.value}>
            {option.content}
          </Radio>
        ))}
      </RadioContainer>
    </VoteWrapper>
  )
}

const HintContainer = styled.div`
  padding: 0 24px;
  &:last-of-type {
    padding-bottom: 24px;
  }
  .ant-typography {
    color: #ef7b00;
  }
`

const SubmitContainer = styled.div`
  text-align: right;
  padding: 4px 24px 24px;
  .ant-typography {
    font-size: 12px;
    line-height: 1.5;
    color: #ef7b00;
    &:hover {
      color: #ef7b00;
      opacity: 0.6;
    }
  }
`

const CardWrapper = styled.div`
  background-color: #fefaeb;
  > *:not(:first-of-type):not(:last-of-type):not(${HintContainer}) {
    border-top: 1px solid #e0e0dd;
  }
`

const HeaderContainer = styled(Flex)`
  padding: 12px 24px;
  width: 100%;
  display: flex;
`

const Title = styled(Typography.Text)`
  &.ant-typography {
    flex: 1 1 auto;
    font-size: 24px;
    line-height: 1.5;
    font-weight: 500;
    color: #8f540c;
  }
`

const VotedContainer = styled(Typography.Text)`
  flex: 0 0 auto;
  font-size: 12px;
  line-height: 1.5;
  font-weight: 500;
  color: #fff;
  padding: 2px 8px;
  border-radius: 4px;
  background-color: #f8b028;
`

const VoteInline: React.FC<VoteCardProps> = ({
  votedNumber,
  items,
  onSubmit,
  status,
  voted,
  isVotable = true,
  onUnableToVote,
  title,
  unOpenedText,
  endedText,
  votedText,
  peopleVotedText,
}) => {
  const resultsRef = useRef<VoteSelectedProps[]>(items.map(item => ({ id: item.id, selected: undefined })))

  const handleSubmit = () => {
    onSubmit(resultsRef.current)
  }

  const onClickWrapper: MouseEventHandler<HTMLDivElement> = e => {
    if (!isVotable) {
      e.preventDefault()
      e.stopPropagation()
      onUnableToVote?.()
      return
    }
  }

  return (
    <CardWrapper onClick={onClickWrapper}>
      <HeaderContainer align="center" gap={16}>
        <Title ellipsis>{title ?? '趨勢投票'}</Title>
        {status !== 'unopened' ? (
          <VotedContainer>
            <span className="mr-1">
              {new Intl.NumberFormat('zh-TW', { notation: 'compact' }).format(votedNumber)}人
            </span>
            <span>{peopleVotedText ?? '已投票'}</span>
          </VotedContainer>
        ) : null}
      </HeaderContainer>

      {status === 'unopened' ? (
        <HintContainer>
          <Typography.Text italic>({unOpenedText ?? '目前尚未開放'})</Typography.Text>
        </HintContainer>
      ) : (
        <>
          {status === 'ended' ? (
            <HintContainer>
              <Typography.Text italic>({endedText ?? '投票已結束'})</Typography.Text>
            </HintContainer>
          ) : voted ? (
            <HintContainer>
              <Typography.Text italic>({votedText ?? '已參與過投票'})</Typography.Text>
            </HintContainer>
          ) : (
            items.map(item => <VoteContainer key={item.id} {...item} resultsRef={resultsRef} />)
          )}
          <SubmitContainer onClick={handleSubmit}>
            <Typography.Link>
              <Space size={4}>
                參與投票，看結果
                <ArrowRightOutlined />
              </Space>
            </Typography.Link>
          </SubmitContainer>
        </>
      )}
    </CardWrapper>
  )
}

export default VoteInline
