'use client'

import { BREAK_POINT } from 'src/contexts/ResponsiveContext'
import styled from 'styled-components'

export const StyledContentArticle = styled.article`
  line-height: 1.875rem;
  color: var(--theme-text-color, rgb(85, 85, 85));
  margin-bottom: 1.5rem;
  text-align: justify;
  text-justify: inter-character;

  a {
    color: var(--theme-link-color, var(--theme-primary-color));
    &:hover {
      filter: brightness(0.6);
      text-decoration: underline;
    }
  }
  ul,
  ol {
    padding-left: 1.5rem;
  }
  ul {
    list-style: disc;
  }
  ol {
    list-style: decimal;
  }
  figure,
  figure img {
    text-align: center;
    max-width: 100%;
    height: auto;
    margin: 20px auto;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  figcaption {
    text-align: center;
    line-height: 1.2;
  }
  b,
  strong {
    font-weight: 500;
    @media screen and (min-width: ${BREAK_POINT}px) {
      font-weight: bold;
    }
  }
  blockquote {
    border-left: 6px solid var(--theme-primary-color) !important;
    position: relative;
    line-height: 1.5em;
    font-weight: 100;
    margin: 0;
    margin-bottom: 10px;
    padding: 1rem 0.1rem;
    @media screen and (min-width: ${BREAK_POINT}px) {
      padding-left: 0.6em;
    }
    &.instagram-media-registered,
    &.instagram-media {
      border: none !important;
      text-align: center;
      svg g {
        fill: #ccc;
      }
    }
  }
  blockquote > h1,
  blockquote > h2,
  blockquote > h3,
  blockquote > h4,
  blockquote > h5,
  blockquote > h6,
  blockquote p,
  blockquote span {
    line-height: 1;
    letter-spacing: 1px;
    @media screen and (min-width: ${BREAK_POINT}px) {
      line-height: 1.2;
    }
  }
  iframe {
    margin: 20px auto !important;
    min-width: unset !important;
    max-width: 100%;
    display: flex;
  }

  .embed-container {
    --iframe-ratio-default: var(--iframe-ratio-percentage-default, 56.25%);
    --iframe-ratio-percentage: var(--iframe-ratio-percentage-mobile, var(--iframe-ratio-default));
    @media screen and (min-width: ${BREAK_POINT}px) {
      --iframe-ratio-percentage: var(--iframe-ratio-percentage-desktop, var(--iframe-ratio-default));
    }

    position: relative;
    width: 100%;
    height: 0;
    padding-top: var(--iframe-ratio-percentage);
    overflow: hidden;

    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }

  .youtube-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    width: 100%;
    height: 0;
    overflow: hidden;

    iframe,
    .react-player {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .react-player__shadow {
      position: relative;
      background: red !important;
      border-radius: 50% / 10% !important;
      height: 66px !important;
      width: 88px !important;

      &:before {
        background: inherit;
        border-radius: 5% / 50%;
        bottom: 9%;
        content: '';
        left: -5%;
        position: absolute;
        right: -5%;
        top: 9%;
      }
    }
    .react-player__play-icon {
      z-index: 10;
    }
  }
  div[data-oembed-url] {
    display: flex;
    justify-content: center;
  }

  .twitter-tweet {
    margin: 0 auto;
  }

  table {
    border-spacing: 1;
    border-collapse: collapse;
    width: 100%;
    min-width: 100%;
    margin: 0 auto;
    position: relative;
    thead {
      tr {
        height: 60px;
        background: #36304a;
        color: white;
      }
    }
    tbody {
      tr {
        height: 50px;
      }
      tr:nth-child(even) {
        background-color: #f5f5f5;
      }
    }
    td,
    th {
      padding: 5px 15px;
    }
  }

  pre {
    background-color: #f7f7f7;
    border-radius: 4px;
    padding: 16px;
    white-space: pre-line;
  }
`
