import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { Divider, Flex, Space, Tag, Typography } from 'antd'
import React, { Fragment, useMemo } from 'react'
import styled from 'styled-components'
import type { VoteResultOptionProps, VoteResultProps } from '../voteResultList/VoteResultList'

export interface VoteResultWithAnswerProps extends Omit<VoteResultProps, 'options'> {
  options: VoteResultOptionProps[]
  answer: React.Key
  selected: React.Key
  explanation: string
}

interface VoteResultWithAnswerConfigProps {}

export interface VoteResultListWithAnswerProps extends VoteResultWithAnswerConfigProps {
  votedDate: string
  items: VoteResultWithAnswerProps[]
  duration: {
    from: string
    due: string
  }
}

const CardHeader = styled(Typography.Text)`
  padding: 12px;
  font-size: 18px;
  line-height: 1.44;
  font-weight: 500;
  color: #33301e;
`

const Result = styled.div`
  margin: 0 12px;
  .ant-typography {
    &.option-index {
      font-size: 16px;
      line-height: 1.5;
    }
  }
`

const Option = styled.div`
  position: relative;
  margin: 0 12px;
  padding-left: 22px;
  .anticon {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`

const CardBody = styled.div<{ $correct: boolean }>`
  .ant-typography {
    font-size: 14px;
    line-height: 1.428;
    color: #858378;
  }
  .ant-tag {
    margin-inline-end: 0;
  }
  ${Result} {
    .ant-typography {
      color: ${props => (props.$correct ? '#75BF46' : '#eb5232')};
    }
  }
  ${Option} {
    .anticon {
      &,
      + .ant-typography {
        color: ${props => (props.$correct ? '#75BF46' : '#eb5232')};
      }
    }
  }
`

const Explain = styled.div`
  margin: 0 12px;
  padding: 8px 8px 8px 24px;
  background-color: #fff8f2;
  border-left: 1px solid #eb5232;
  .ant-typography {
    color: #eb5232;
    margin-bottom: 0;
  }
`

const VoteResultCard: React.FC<VoteResultWithAnswerProps & { number: number }> = ({
  number,
  question,
  options,
  answer,
  selected,
  explanation,
}) => {
  const selectedIndex = options.findIndex(option => option.id === selected)
  const answerIndex = options.findIndex(option => option.id === answer)

  return (
    <Flex vertical gap={12}>
      <CardHeader>
        Q{number}. {question}
      </CardHeader>
      <CardBody $correct={answer === selected}>
        <Flex vertical gap={12}>
          <Result>
            <Space size={12}>
              <Tag color={answer === selected ? '#75BF46' : '#eb5232'}>{answer === selected ? '正確' : '錯誤'}</Tag>
              <Space size={0}>
                <Typography.Text>你的答案：</Typography.Text>
                <Typography.Text className="option-index">{String.fromCharCode(selectedIndex + 97)}</Typography.Text>
              </Space>
              <Typography.Text>
                正確答案：
                <Typography.Text className="option-index">{String.fromCharCode(answerIndex + 97)}</Typography.Text>
              </Typography.Text>
            </Space>
          </Result>
          {options.map((option, index) => (
            <Option key={option.id}>
              {option.id !== selected ? null : answer === selected ? (
                <CheckCircleOutlined size={14} />
              ) : (
                <CloseCircleOutlined size={14} />
              )}
              <Typography.Text>
                ({String.fromCharCode(index + 97)}) {option.content}
              </Typography.Text>
            </Option>
          ))}
          {answer !== selected ? (
            <Explain>
              <Flex vertical align="flex-start" gap={8}>
                <Tag color="#eb5232">錯誤</Tag>
                <Typography.Paragraph>{explanation}</Typography.Paragraph>
              </Flex>
            </Explain>
          ) : null}
        </Flex>
      </CardBody>
    </Flex>
  )
}

const ListContainer = styled.div`
  border: 1px solid #e0e0dd;
`

const Header = styled.div`
  padding: 12px 0;
  background-color: #fafafa;
  border-bottom: 1px solid #e0e0dd;
  .ant-divider-vertical {
    min-height: 37px;
    margin-inline: 0;
  }
  .ant-typography {
    font-size: 12px;
    line-height: 1.5;
    font-weight: 500;
    color: #33301e;
  }
`

const Strong = styled(Typography.Text)`
  &.ant-typography {
    font-size: 14px;
    line-height: 1.428;
  }
`

const Body = styled.div`
  padding: 12px;
  .ant-divider {
    margin: auto;
  }
`

const Duration = styled(Typography.Text)`
  &.ant-typography {
    font-size: 12px;
    line-height: 1.5;
    color: #c2c1bb;
    text-align: center;
  }
`

const VoteResultListWithAnswer: React.FC<VoteResultListWithAnswerProps> = ({ votedDate, items, duration }) => {
  const currentCorrectPercentage = useMemo(
    () => (items.reduce((acc, cur) => (cur.selected === cur.answer ? acc + 1 : acc), 0) / items.length) * 100,
    [items],
  )
  const averageCorrectPercentage = useMemo(() => {
    const correctPercentages = items.map(item => {
      const totalVotedCount = item.options.reduce((acc, cur) => acc + cur.amount, 0)
      const correctCount = item.options.find(option => option.id === item.answer)?.amount || 0
      return (correctCount / totalVotedCount) * 100
    })

    return correctPercentages.reduce((acc, cur) => acc + cur, 0) / correctPercentages.length
  }, [items])

  return (
    <ListContainer>
      <Header>
        <Flex justify="space-evenly" align="center">
          <Strong>QA 解答</Strong>
          <Divider type="vertical" />
          <Typography.Text>{votedDate}</Typography.Text>
          <Divider type="vertical" />
          <Typography.Text>正確率 {Math.round(currentCorrectPercentage)}%</Typography.Text>
          <Divider type="vertical" />
          <Typography.Text>平均 {Math.round(averageCorrectPercentage)}%</Typography.Text>
        </Flex>
      </Header>
      <Body>
        <Flex vertical gap={20}>
          {items.map((vote, index) => (
            <Fragment key={vote.id}>
              <VoteResultCard number={index + 1} {...vote} />
              <Divider />
            </Fragment>
          ))}
          <Duration>
            統計時間：{duration.from} - {duration.due}
          </Duration>
        </Flex>
      </Body>
    </ListContainer>
  )
}

export default VoteResultListWithAnswer
